import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { CookieBanner } from "@palmabit/react-cookie-law";
import ReactGA from "react-ga";

import "./common/general.scss";
import "./common/fonts.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import SiteNavBar from "./common/navbar";
import Footer from "./common/footer";

const Home = lazy(() => import("./pages/home"));
const WhoWeArePage = lazy(() => import("./pages/who-we-are"));
const WhatWeDoPage = lazy(() => import("./pages/what-we-do"));
const Contact = lazy(() => import("./pages/contact"));
const PastProjects = lazy(() => import("./pages/past-projects"));
const HealthRhythmsPage = lazy(() => import("./pages/health-rhythms"));
const Page = lazy(() => import("./pages/page"));
const BlogPost = lazy(() => import("./pages/blog"));
const PageNotFound = lazy(() => import("./pages/page-not-found"));

const App = () => {
  const [canTrackUser, setCanTrackUser] = useState(false);
  const [isGaInitialised, setIsGaInitialised] = useState(false);

  useEffect(() => {
    // Are we allowed to track the user?
    if (
      canTrackUser ||
      (Cookies.get("rcl_consent_given") &&
        Cookies.get("rcl_preferences_consent") &&
        Cookies.get("rcl_statistics_consent"))
    ) {
      if (!canTrackUser) setCanTrackUser(true); // avoids needing to read cookies again

      // Only init GA once
      if (!isGaInitialised) {
        ReactGA.initialize("UA-192066174-1");
        setIsGaInitialised(true);
      }

      // Track the locaton in GA
      ReactGA.pageview(window.location.pathname);
    }
  }, [canTrackUser, isGaInitialised]);

  return (
    <>
      <CookieBanner
        message="We use cookies to understand your site usage. That way we can tailor the site navigation, content and marketing to your specific needs."
        wholeDomain={true}
        policyLink="/page/cklqsf780o7bs0a04p0lw0rea"
      />
      <Router>
        <Suspense fallback={<FallbackComponent />}>
          <SiteNavBar />
          <div className="page-wrapper">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/who-we-are" component={WhoWeArePage} />
              <Route exact path="/what-we-do" component={WhatWeDoPage} />
              <Route
                exact
                path="/health-rhythms"
                component={HealthRhythmsPage}
              />
              <Route exact path="/contact" component={Contact} />
              <Route
                exact
                path="/past-projects-blog"
                component={PastProjects}
              />
              <Route path="/page/:id" component={Page} />
              <Route path="/blog/:id" component={BlogPost} />
              <Route
                path="/admin"
                component={() => {
                  window.location.href = "https://app.graphcms.com/";
                  return null;
                }}
              />
              <Route component={PageNotFound} />
            </Switch>
          </div>
          <Footer />
        </Suspense>
      </Router>
    </>
  );
};

const FallbackComponent = () => (
  <>
    <SiteNavBar />
    <div className="page-wrapper" />
    <Footer />
  </>
);

export default App;
