import React from "react";
import { Link } from "react-router-dom";

import FacebookIcon from "../assets/facebook_icon.png";
import InstagramIcon from "../assets/instagram_icon.png";
import YouTubeIcon from "../assets/youtube_icon.png";
import TwitterIcon from "../assets/twitter_icon.png";

const Footer = () => (
  <footer>
    <section>
      <h5>Let's create together</h5>
      <Link to="/who-we-are">Who We Are</Link>
      <Link to="/what-we-do">What We Do</Link>
      <Link to="/health-rhythms">HealthRHYTHMS</Link>
      <Link to="/past-projects-blog">Past Projects Blog</Link>
      <Link to="/page/cklqsf780o7bs0a04p0lw0rea">Cookies Policy</Link>
    </section>
    <section>
      <h5>Let's connect</h5>
      <div className="social-links">
        <a href="https://m.facebook.com/letsallcreate/">
          <img src={FacebookIcon} alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/letsallcreate/">
          <img src={InstagramIcon} alt="Instagram" />
        </a>
        <a href="https://twitter.com/letsallcreate">
          <img src={TwitterIcon} alt="Instagram" />
        </a>
        <a href="https://www.youtube.com/channel/UC0pheKLFeTUEUMH5xHGAEzQ">
          <img src={YouTubeIcon} alt="YouTube" />
        </a>
      </div>
      <Link to="/contact">Contact Us</Link>
    </section>
  </footer>
);

export default Footer;
