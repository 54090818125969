import React from "react";
import { Navbar, Nav } from "react-bootstrap";

import Logo from "../assets/letsallcreate.png";

const SiteNavBar = () => (
  <Navbar collapseOnSelect expand="lg" variant="light">
    <Navbar.Brand href="/" className="mobile-nav-logo">
      <img src={Logo} alt="Lets All Create logo" />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse
      id="responsive-navbar-nav"
      className="justify-content-center"
    >
      <Nav className="align-items-center">
        <Nav.Link href="/who-we-are">Who We Are</Nav.Link>
        <Nav.Link href="/what-we-do">What We Do</Nav.Link>
        <Navbar.Brand href="/" className="desktop-nav-logo">
          <img src={Logo} alt="Lets All Create logo" />
        </Navbar.Brand>
        <Nav.Link href="/health-rhythms">HealthRHYTHMS</Nav.Link>
        <Nav.Link href="/contact">Get In Touch</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default SiteNavBar;
